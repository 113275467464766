import {
  Stack,
  Flex,
  Container,
  Heading,
  Link,
  Button,
  Text,
  Box,
  Image,
  Grid,
  GridItem,
  useDisclosure,
  FormControl,
  Textarea,
} from '@chakra-ui/react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Footer from './shared/Footer'
import Language from './shared/Language'
import StarRatings from 'react-star-ratings'
import { db, QueueData, ReviewData } from '@flowby/shared-firebase'
import { theme } from '@flowby/shared-general'
import ConfirmAlert from './shared/ConfirmAlert'
import { deviceDetect, browserName, getUA } from 'react-device-detect'
import MyTurn from './Queue/MyTurn'

export default function Thanks({
  store,
  queue,
  queueNumber,
  queueNumberId,
  queueData,
  leaver,
  queueClosed,
  keepMyTurnOnThanks
}: {
  store: string
  queue: string
  queueNumber?: string
  queueNumberId?: string
  queueData: QueueData
  leaver: boolean
  queueClosed: boolean
  keepMyTurnOnThanks: boolean
}) {
  const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
  const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)
  const { t } = useTranslation()
  const reviewDisclosure = useDisclosure()
  const navigate = useNavigate()
  const [logoError, setLogoError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [rating, setRating] = useState<number | undefined>(undefined)
  const [message, setMessage] = useState<string | undefined>(undefined)

  const takeNewQueueNumber = async () => {
    setLoading(true)
    const groups = await db.getGroups(store)
    const group = groups.docs.map((group) => {
      const groupData = group.data()
      if (groupData.queues.includes(queue)) {
        return groupData.shortName
      }
    }).filter((group) => group !== undefined)[0]
    setLoading(false)
    if (group) {
      navigate(`/${store}/g/${group}`)
    } else {
      navigate(`/${store}/q/${queue}`)
    }
  }

  const submitReviewData = async (submitMessage: string | undefined) => {
    reviewDisclosure.onClose()
    const deviceInfo = deviceDetect(getUA)
    try {
      const reviewData: ReviewData = {
        rating: rating || null,
        message: submitMessage || null,
        store,
        queue,
        queuer_id: queueNumberId || null,
        device_vendor: deviceInfo.vendor || null,
        device_os: deviceInfo.os || null,
        device_model: deviceInfo.model || null,
        browser: browserName || null,
        screen_width: vw || null,
        screen_height: vh || null,
      }
      await db.postReviewData(reviewData)
    } catch (e) {
      console.error('Error when submitting review data', e)
    }
  }

  return (
    <Stack minH="100%" justify="space-between" data-testid="thanks-view">
      <ConfirmAlert
        disclosure={reviewDisclosure}
        headerText={t('review-experience')}
        content={
          <Stack spacing={4}>
            <StarRatings
              rating={rating}
              starRatedColor={theme.colors.colors.green['500']}
              starEmptyColor={theme.colors.colors.gray['100']}
              starHoverColor={theme.colors.colors.green['500']}
              changeRating={rating => {
                setRating(rating)
                reviewDisclosure.onOpen()
              }}
              numberOfStars={5}
              name="rating"
            />
            <FormControl id="reviewMessage">
              <Textarea
                name="reviewMessage"
                value={message}
                onChange={e => {
                  setMessage(e.target.value)
                }}
              />
            </FormControl>
          </Stack>
        }
        noText={t('no')}
        yesText={t('submit')}
        yesAction={() => {
          submitReviewData(message)
        }}
        noAction={() => {
          submitReviewData(undefined)
        }}
      />
      <Grid py={2} px={2} templateRows="repeat(1, 1fr)" templateColumns="repeat(4, 1fr)" gap={2}>
        <GridItem colSpan={1} pt={1} pl={1} zIndex={1500}>
          <Language store={store} />
        </GridItem>
        <GridItem colSpan={2} mb={-2} pt={1}>
          <Box position="relative" w="100%" h="100%" textAlign="center" alignItems="center">
            {!logoError && (
              <Image
                maxH={43}
                margin="auto"
                fit="contain"
                src={`https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_ENV === 'dev' ? 'flowby-io-dev' : 'flowby-io'}.appspot.com/o/stores%2F${store}%2Flogo.png?alt=media`}
                onError={() => setLogoError(true)}
              />
            )}
          </Box>
        </GridItem>
        <GridItem colSpan={1}></GridItem>
      </Grid>
      <Container flex={1} display="flex" flexDir="column" alignSelf="center">
        <Flex pt={2} pb={2} justifyContent="center" alignItems="center" textAlign="center">
          <Heading>{queueData.displayName}</Heading>
        </Flex>
        {!queueClosed && !keepMyTurnOnThanks && (
          <Stack alignItems="center" spacing={4}>
            <>
              <Stack spacing={4} textAlign="center" alignItems="center">
                {!leaver && (
                  <Text fontSize="xl">{t('turn-passed')}</Text>
                )}
                {queueNumber && (
                  <Box bgColor="gray.200" borderRadius="0.375rem" px={6}>
                    <Text fontWeight="bold" fontSize="6xl">
                      {queueNumber}
                    </Text>
                  </Box>
                )}
              </Stack>
              <Stack textAlign="center" spacing={4}>
                <Text fontSize="xl">
                  {t('turn-passed-thanks')} <span className="font-bold">Flowby</span>!
                </Text>
                <Box>
                  <Text fontSize="xl">{`${t('give-feedback')} `}</Text>
                  <StarRatings
                    rating={rating}
                    starRatedColor={theme.colors.colors.green['500']}
                    starEmptyColor={theme.colors.colors.gray['100']}
                    starHoverColor={theme.colors.colors.green['500']}
                    changeRating={rating => {
                      setRating(rating)
                      reviewDisclosure.onOpen()
                    }}
                    numberOfStars={5}
                    name="rating"
                  />
                </Box>
                <Text fontSize="xl">
                  {`${t('get-more-info')} `}
                  <Link fontWeight="bold" color="green.600" href="https://flowby.io" isExternal={true}>
                    flowby.io
                  </Link>
                  .
                </Text>
              </Stack>
            </>
            <Button
              id="thanks-take-new-number"
              isLoading={loading}
              onClick={takeNewQueueNumber}
            >
              {t('take-new-number')}
            </Button>
          </Stack>
        )}
        {!queueClosed && keepMyTurnOnThanks && (
          <MyTurn queueData={queueData} myNumber={queueNumber || '-'} />
        )}
        {queueClosed && (
          <Stack textAlign="center" alignItems="center" spacing={4} py={4}>
            <Text fontSize="xl">{t('queue-closed')}. {t('queue-closed-try-later')}</Text>
            <Text fontSize="xl">
              {`${t('get-more-info')} `}
              <Link fontWeight="bold" color="green.600" href="https://flowby.io" isExternal={true}>
                flowby.io
              </Link>
              .
            </Text>
          </Stack>
        )}
      </Container>
      <Footer />
    </Stack>
  )
}
